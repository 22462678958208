<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container class="bg-primary-g">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'DefaultLayout',
  created() {
    if (this.isDark) this.$q.dark.toggle();
  }
};
</script>
